<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card elevation="2" outlined shaped tile>
      <v-card-title>Códigos Operador POS</v-card-title>

      <v-card-text>
        <b-alert show variant="info"
          ><b>Importante</b>: En cada import que hagas, se borraran los datos
          previos y se cargaran los datos del archivo importado</b-alert
        >

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isLoading"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonTextBrandSales }}
            </v-btn>
            <input
              ref="uploaderTariffs"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="importFailedRows">
          <v-col>
            <b-alert show variant="danger"
              ><b>Importación cancelada</b>: revisa los siguientes
              errores</b-alert
            >
            <b-table
              style="width: 100%"
              sticky-header="500px"
              dark
              bordered
              small
              striped
              :items="importFailedRows"
            >
            </b-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="poscodes-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="tableColumns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import download from "js-file-download";

export default {
  name: "PosCodes",
  components: {},
  data: function() {
    return {
      importFailedRows: null,
      tableData: [],
      tableColumns: [
        {
          key: "posId",
          title: "ID Pos",
          field: "posId",
          align: "left",
          width: 100,
          sortBy: ""
        },
        {
          key: "pos",
          title: "Pos",
          field: "pos",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.po && row.po.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["pos"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`pos`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["pos"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "pos")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "operatorId",
          title: "ID operador",
          field: "operatorId",
          align: "left",
          width: 120,
          sortBy: ""
        },
        {
          key: "operator",
          title: "Operador",
          field: "operator",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.operator && row.operator.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["operator"]}
                    apiUrl={`${this.filterlist_fetch_url}`}
                    columnName={`operator`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["operator"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "operator")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "code",
          title: "Código Operador",
          field: "code",
          align: "left",
          width: 120,
          sortBy: ""
        }
      ],
      totaltableData: 0,
      importExcelTemplatePathName: null,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      filterable_col_list: ["pos", "product"],
      filterCriteria: {
        pos: {},
        product: {}
      },
      filterlist_fetch_url: "products/tariffs/filterlist",
      showEmpty: true,

      /********** Import and Export Excel **********/
      isLoading: false,
      selectedFile: null,
      defaultbuttonTextBrandSales: "Import",

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********************** Loading Spinner Overlay **************************/
      isLoadingSpinner: false
    };
  },
  watch: {},
  computed: {
    buttonTextBrandSales() {
      return this.isLoading ? "Uploading..." : this.defaultbuttonTextBrandSales;
    }
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#poscodes-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    async getDataFromApi() {
      // this.show();
      this.isLoadingSpinner = true;
      try {
        const { pageIndex, pageSize, sorting, filterCriteria } = this;
        let query_params_string = this.getQueryParamsGlobal({
          pageIndex,
          pageSize,
          sorting,
          filterCriteria,
          filterableColumnsSelector: "#poscodes-table .filterable-column"
        });
        let fetch_url = "pos/operator-codes/index";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        // this.close();
        this.isLoadingSpinner = false;
      } catch (error) {
        // this.close();
        this.isLoadingSpinner = false;
      }
    },
    async downloadExcel() {
      // this.show();
      this.isLoadingSpinner = true;
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector: "#poscodes-table .filterable-column"
      });
      let fetch_url = "pos/operator-codes/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "poscodes_export.xlsx");
      } catch (error) {}
      // this.close();
      this.isLoadingSpinner = false;
    },
    onUploadExcelClick() {
      this.isLoading = true;
      window.addEventListener(
        "focus",
        () => {
          this.isLoading = false;
        },
        { once: false }
      );
      this.$refs.uploaderTariffs.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isLoading = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploaderTariffs.value = "";
        try {
          this.importFailedRows = null;
          const response = await ApiService.post(
            `pos/operator-codes/uploadexcel/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;

          this.importFailedRows = failedRows;

          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            await this.getDataFromApi();
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isLoading = false;
          this.isLoadingSpinner = false;
        } catch (error) {
          console.log(error);
          this.isLoading = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
